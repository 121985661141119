import accessLevel from "@/enums/accessLevel";
import sampleStorage from "@/enums/sampleStorage";
import precision from "@/enums/precision";

export default [
    {
        name: "AccessLevel",
        component: accessLevel
    },
    {
        name: "SampleStorage",
        component: sampleStorage
    },
    {
        name: "Precision",
        component: precision
    },
];
